.main {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* background-image: url(/images/Hero-Banner.jpg); */
  min-height: 570px;
  padding-top: 65px !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
}
.mainlanding {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  /* background-image: url(/images/Hero-Banner.jpg); */
  min-height: 500px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 60px; */
  position: relative;
  object-fit: cover;
}
.headerContainer {
  margin-top: 180px;
  @media only screen and (max-width: 425px) {
    margin-top: 100px;
  }
}
.main .header_title {
  font-size: 50px;
}
.main .header_paragraph {
  font-size: 20px;
}
.main .title {
  color: #fff;
  font-size: 46px;
  line-height: 60px;
  font-weight: bold;
}

.main .description {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 80px;
}

.main form {
  min-width: 880px;
  margin-left: 50px;
}

.main form input {
  min-height: 60px;
  border-radius: 0;
  border-left: 0;
}

.main form input::placeholder {
  font-size: 15px;
}

.main form input:focus {
  box-shadow: none;
  outline: 0;
}

.main form ul {
  margin-bottom: 0;
  margin-left: -10px;
}

.main form ul li {
  list-style: none;
  margin-right: 15px;
}

.main form ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  line-height: 60px;
}

.main form button {
  min-height: 60px;
  font-size: 15px;
}

.footer {
  width: 100%;
  height: 100px;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.footer a:hover {
  color: #3165ba;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin-top: 3rem;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 45%;
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

.right_nav a {
  margin-right: 30px;
}

.right_nav a:last-child {
  margin-right: 0px;
}

.left_nav a {
  margin-left: 25px;

  color: #222 !important;
}
.featuredReview {
  padding-top: 150px;
}

.featured {
  padding: 2rem 0;
  position: relative;
}

.featured h4 {
  font-size: 16px;
  font-weight: normal;

  color: rgba(94, 94, 94, 0.8);
  margin-bottom: 40px;
}

.featured .btn_container {
  position: absolute;
  right: 10px;
  top: 0px;
}

.productCard {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(220, 220, 220, 0.6);
  border-radius: 6px;
  overflow: hidden;
}

.productCard .category_name {
  font-size: 13px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  margin-top: 10px;
  padding-left: 20px;
}

.productCard .productTitle {
  padding-left: 20px;
  margin-bottom: 0;
}

.productCard .productTitle a {
  color: #333333;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
}

.productCard .rating {
  padding-left: 20px;
  /* position: relative; */
  /* top: 20px; */
}

.productCard .currency {
  padding-right: 40px;
}

.productCard .currency p {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}

.productCard .rating {
  font-size: 15px;
  color: #333333;
  /* line-height: 20px; */
}

.productCard .rating svg {
  color: #f7ca18;
  margin-top: -2px !important;
}

.productCard .productImage {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.productCard .image_container svg {
  color: #fff;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.productCard .badge {
  position: absolute;
  top: 0;
  margin: -4px;
}

.productCard .coupon_badge {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #3165ba !important;
  padding: 10px 15px;
  border-radius: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.productCard .coupon_badge a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
}

.btn_curved {
  background: none !important;
  color: #3165ba !important;
  border: 1.2px solid #3165ba;
  border-radius: 24px;
  padding: 10px 20px !important;
}

.grey_featured {
  background: #f6f2f9;
  cursor: pointer;
}

.grey_featured_product p {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
}

.variants h4 {
  margin-bottom: 75px;
}

.variants h5 {
  font-size: 20px;
  font-weight: 500;
}

.variants p {
  font-size: 15px;
}

.variants img,
.variants h5 {
  cursor: pointer;
}

.cta_section {
  background: url(/images/cta-bg.jpg) center center no-repeat;
  background-size: cover;
  min-height: auto;
  display: flex;
  align-items: center;
  min-height: 540px;
}

.cta_section h4 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.cta_section h2 {
  color: #fff;
  font-size: 42px;
  font-weight: bold;
}

.cta_section p {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}

.cta_section a {
  color: #333333;
  font-size: 15px;
  background: #ffffff;
  font-size: 15px;
  line-height: 30px;
}

.testimonial_static {
  margin-bottom: 40px;
}

.testimonial_static:last-child {
  margin-bottom: 0;
}

.testimonial_static:first-child {
  margin-top: 28px;
}

.testimonial_static h5 {
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;
  color: #000;
}

.testimonial_static p {
  font-size: 14px;
  font-weight: 400;
}

.reviewBox {
  border: 1px solid rgba(220, 220, 220, 0.6);
  border-radius: 3px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);
  padding: 25px;
  box-shadow: rgb(243, 243, 243) 6px 6px, rgba(255, 255, 255, 0.939) 12px 18px,
    0 0 3px 0 rgba(0, 0, 0, 0.06) !important;
  border: solid 0.5px rgba(220, 220, 220, 0.6);
}

.reviewBox svg {
  color: #f7ca18;
}

.reviewBox .reviewTitle {
  margin-bottom: 0;
}

.reviewBox .reviewTitle svg {
  margin-right: 5px;
}

.reviewBox .reviewDesc {
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
}

.reviews h4 {
  margin-bottom: 40px;
}

.reviewBox .more_details h4 {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.btn_review {
  background: #3165ba;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.subscribe_block {
  background: #3165ba;
  padding: 70px 0;
  border-radius: 12px;
  margin-bottom: 60px;
}

.subscribe_block h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 39px;
}

.subscribe_block h6 {
  color: #fff;
  font-size: 16px;
}

.subscribe_block input {
  min-height: 60px;
}

.subscribe_block input::placeholder {
  color: rgba(38, 37, 37, 0.5);
  font-size: 15px;
}

.subscribe_block button {
  background: #222;
  color: #fff;
  border: 1px solid #222;
  font-size: 16px;
}
.discoverRetreats {
  margin-top: 20px;
}
.discoverRetreats h4 {
  font-size: 17px;
  font-weight: 600;
  color: #333333;
  margin-top: 8px;
}
.discoverRetreats h6 {
  font-size: 11px;
  font-weight: 500;
}
.StillSearchingBg {
  min-height: 190px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 50px 0; */
}
.StillSearching h4 {
  font-size: 24px;
  font-weight: 600;
}
.StillSearching h6 {
  font-size: 15px;
  font-weight: 400;
}
.StillSearching span {
  margin-top: 3px;
  font-size: 9px;
}
.StillSearching img {
  max-width: 50px;
}

.ReviewBlock h4 {
  font-size: 19px;
  font-weight: 600;
  color: #000000;
}
.ReviewBlock h6 {
  font-size: 15.5px;
  font-weight: 400;
  color: #333333;
}
.ReviewBlock img {
  max-width: 65px;
}


.ReviewBlock p {
  color: #333333;
  font-size: 15px;
  font-weight: 500;
}
.ReviewBlock h5 {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}
.ReviewBlock h3 {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
}
.ReviewBlock button {
  background-color: #3165ba;
  border-radius: 5px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .StillSearchingBg {
    /* min-height: 190px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
  }
}
@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }

  .right_nav {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
  }
  .discoverRetreats h6 {
    font-size: 13px;
    font-weight: 500;
  }
  .cta_section {
    background-position: inherit;
  }
}
@media (max-width: 480px) {
  .header_title {
    font-size: 34px;
  }
  .header_paragraph {
    font-size: 18px !important;
  }
  .cta_section {
    min-height: 360px;
  }
  .cta_section h2 {
    font-size: 25px;
  }
  .cta_section h4 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
  }
  .cta_section p {
    font-size: 18px;
  }
  .discoverRetreats h4 {
    margin-top: 13px;
  }
  .StillSearching h4 {
    font-size: 20px;
    font-weight: 600;
  }
  .StillSearching h6 {
    font-size: 12px;
    font-weight: 400;
  }
  .StillSearching span {
    font-size: 7px;
  }
  .subscribe {
    margin-top: 20px;
  }
  .main {
    /* background-image: url(/images/hero_BannerMobile.svg);
     */
    /* background-image: url(/images/hero_BannerMobile.svg); */
    min-height: 620px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 25px; */
    padding-bottom: 130px;
  }
  .mainlanding {
    /* background-image: url(/images/hero_BannerMobile.svg);
     */
    /* background-image: url(/images/hero_BannerMobile.svg); */
    min-height: 480px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
  .discoverRetreats h4 {
    font-size: 18px;
  }
  .ReviewBlock h4 {
    font-size: 17px;
  }
  .ReviewBlock h6 {
    font-size: 14px;
  }
  .ReviewBlock h3 {
    font-size: 14px;
  }
  .ReviewBlock h5 {
    font-size: 13px;
  }
  .ReviewBlock p {
    font-size: 14px;
  }
  .ReviewBlock button {
    font-size: 13px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .main {
    min-height: 700px;
    /* margin-top: 35px; */
    padding-bottom: 90px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mainlanding {
    min-height: 500px;
    margin-top: 0;
  }
  .header_title {
    font-size: 40px !important;
  }
  .cta_section {
    min-height: 400px;
  }
  .cta_section h2 {
    font-size: 30px;
  }
  .cta_section p {
    font-size: 19px;
  }
  .StillSearching h4 {
    font-size: 23px;
    font-weight: 600;
  }
  .StillSearching h6 {
    font-size: 13px;
    font-weight: 400;
  }
  .subscribe {
    margin-top: 20px;
  }
  .discoverRetreats h4 {
    margin-top: 14px;
  }
  .ReviewBlock h4 {
    font-size: 20px;
  }
  .ReviewBlock h6 {
    font-size: 15px;
  }
  .ReviewBlock h5 {
    font-size: 14px;
  }
  .ReviewBlock p {
    font-size: 15px;
  }
  .ReviewBlock button {
    font-size: 13px;
  }

  .discoverRetreats h4 {
    font-size: 14.5px;
    font-weight: 600;
    color: #333333;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 575px) {
  .discoverRetreats h4 {
    font-size: 15.5px !important;
    font-weight: 600;
    color: #333333;
    margin-top: 20px;
  }
  .discoverRetreats h6 {
    font-size: 15px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .discoverRetreats h4 {
    font-size: 14.5px !important;
    font-weight: 600;
    color: #333333;
    margin-top: 4px;
    margin-bottom: 2px;
  }
  .discoverRetreats h6 {
    font-size: 13px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe {
    margin-top: 20px !important;
  }
  .discoverRetreats h6 {
    font-size: 12px;
  }
  .discoverRetreats h4 {
    font-size: 16px;
    margin-top: 10px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .header_title {
    font-size: 40px !important;
  }
  .cta_section {
    min-height: 450px;
  }
  .cta_section h2 {
    font-size: 36px;
  }
  .cta_section p {
    font-size: 20px;
  }
  .StillSearching h4 {
    font-size: 19px;
    font-weight: 600;
  }
  .StillSearching h6 {
    font-size: 13px;
    font-weight: 400;
  }

  .StillSearching span {
    font-size: 6px;
  }
  .ReviewBlock h4 {
    font-size: 17px;
  }
  .ReviewBlock h6 {
    font-size: 14px;
  }
  .ReviewBlock p {
    font-size: 13px;
  }
  .ReviewBlock button {
    font-size: 13px;
  }
  .ReviewBlock h5 {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .ReviewBlock h4 {
    font-size: 16.5px;
  }
  .ReviewBlock h5 {
    font-size: 14px;
  }
  .StillSearching h4 {
    font-size: 20px;
    font-weight: 600;
  }
  .StillSearching h6 {
    font-size: 13px;
    font-weight: 400;
  }
}

.caret-off::before {
  display: none;
}
.caret-off::after {
  display: none;
}
.dropdown-toggle::after {
  border: none;
}

.bg_photoVideos {
  width: 1920px;
  height: 1080px;
  padding: 60px 198px 0 200px;
  background-color: var(--white-two);
}
